import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label, black }) => {
  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
      style={{color: black? "#000":"rgba(30, 30, 30, 0.8)"}}
      
    >
      {label}
    </Link>
  );
};

export default ButtonMailto;
