import {
  FETCH_PORTLAND_LOCATION,
  FETCH_TUSCON_LOCATION,
  FETCH_AUSTIN_LOCATION,
  FETCH_BOSTON_LOCATION,
  FETCH_INDIANAPOLIS_LOCATION,
  FETCH_JACKSONVILLE_LOCATION,
  FETCH_RICHMOND_LOCATION,
  FETCH_STLOUIS_LOCATION,
  GET_ACCORDION_ID,
  PORTLAND_FETCH_INITIATED,
  TUSCON_FETCH_INITIATED,
  AUSTIN_FETCH_INITIATED,
  BOSTON_FETCH_INITIATED,
  INDIANAPOLIS_FETCH_INITIATED,
  JACKSONVILLE_FETCH_INITIATED,
  RICHMOND_FETCH_INITIATED,
  STLOUIS_FETCH_INITIATED,
  SEND_SIGN_UP_NEWSLETTER,
  SEND_SIGN_UP_NEWSLETTER_INITIATED,
} from "../actions/actionTypes";

const initialState = {
  portLandLocations: [],
  tusconLocations: [],
  austinLocations: [],
  bostonLocations: [],
  indianapolisLocations: [],
  jacksonvilleLocations: [],
  richmondLocations: [],
  stlouisLocations: [],
  activeAccordId: 0,
  activelat: 0,
  activelng: 0,
  loading: false,
  success: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PORTLAND_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_PORTLAND_LOCATION:
      return { ...state, portLandLocations: action.payload, loading: false };
    case TUSCON_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_TUSCON_LOCATION:
      return { ...state, tusconLocations: action.payload, loading: false };
    case AUSTIN_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_AUSTIN_LOCATION:
      return { ...state, austinLocations: action.payload, loading: false };
    case BOSTON_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_BOSTON_LOCATION:
      return { ...state, bostonLocations: action.payload, loading: false };
    case INDIANAPOLIS_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_INDIANAPOLIS_LOCATION:
      return {
        ...state,
        indianapolisLocations: action.payload,
        loading: false,
      };
    case JACKSONVILLE_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_JACKSONVILLE_LOCATION:
      return {
        ...state,
        jacksonvilleLocations: action.payload,
        loading: false,
      };
    case RICHMOND_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_RICHMOND_LOCATION:
      return { ...state, richmondLocations: action.payload, loading: false };
    case STLOUIS_FETCH_INITIATED:
      return { ...state, loading: true };
    case FETCH_STLOUIS_LOCATION:
      return { ...state, stlouisLocations: action.payload, loading: false };
    case GET_ACCORDION_ID:
      return {
        ...state,
        activeAccordId: action.payload.id,
        activelat: action.payload.lat,
        activelng: action.payload.lng,
      };
    case SEND_SIGN_UP_NEWSLETTER_INITIATED:
      return { ...state, success: null };
    case SEND_SIGN_UP_NEWSLETTER:
      return { ...state, success: action.payload };

    default:
      return state;
  }
};
