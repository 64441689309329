import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import "../assets/styles/screen.css";
import Accordion from "../components/Accordion";
import SignupComponent from "../components/SignupComponent";
import Form from "../components/Form";
import { getTusconLocations } from "../actions/locationAction";
import MapComponent from "../components/MapComponent";
import Loader from "../assets/shared/Loader";

const Tuscon = () => {
  //destructuring
  const data = useSelector((state) => state.location);
  const locations = data.tusconLocations;
  const loading = data.loading;

  //state maintain here

  const [locSearch, setLocSearch] = useState();
  const [locationData, setLocationData] = useState();
  const [signupValue, setSignupValue] = useState();

  //action dispatching
  const dispatch = useDispatch();

  //useefeect here

  useEffect(() => {
    setLocationData(locations);
  }, [locations]);

  useEffect(() => {
    dispatch(getTusconLocations());
  }, [dispatch]);

  const handleChange = (value) => {
    setLocSearch(value);
    setLocationData(
      locations.filter((location) =>
        location.address.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  const signupHandleChange = (value) => {
    setSignupValue(value);
  };
  const status = useSelector((state) => state.location.success);
  useEffect(() => {
    if (status !== "") {
      setSignupValue("");
    }
  }, [status]);
  return (
    <div>
      <Header locationName={"tuscon"} headerText={"Tucson"} show />
      {loading === false ? (
        <div className="container">
          <div className="location-box">
            <div className="row">
              <div id="accordion" className="col-sm">
                <div className="r-t-6">
                  <Accordion datas={locationData} />
                </div>
              </div>
              <div id="map" className="col-sm">
                <div className="r-t-6 r-t-7">
                  <Form
                    placeholder="Search by address or zip code"
                    type="search"
                    value={locSearch}
                    onChange={handleChange}
                  />
                  <MapComponent data={locationData} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm"></div>
              <div className="col-sm">
                <div id="signup" className="r-t-6">
                  <SignupComponent
                    type="search"
                    placeholder="Email Address"
                    value={signupValue}
                    onChange={signupHandleChange}
                    status={status}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Tuscon;
