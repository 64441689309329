import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";

const Template = (props) => {
  return (
    <div>
      <Navigation />
      {props.children}
      <Footer />
    </div>
  );
};

export default Template;
