import * as api from "../api";
import * as types from "./actionTypes";

export const getPortlandLocations = () => async (dispatch) => {
  dispatch({ type: types.PORTLAND_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchPortlandLocations();
    dispatch({
      type: types.FETCH_PORTLAND_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getTusconLocations = () => async (dispatch) => {
  dispatch({ type: types.TUSCON_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchTusconLocations();
    dispatch({
      type: types.FETCH_TUSCON_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAustinLocations = () => async (dispatch) => {
  dispatch({ type: types.AUSTIN_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchAustinLocations();
    dispatch({
      type: types.FETCH_AUSTIN_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getBostonLocations = () => async (dispatch) => {
  dispatch({ type: types.BOSTON_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchBostonLocations();
    dispatch({
      type: types.FETCH_BOSTON_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getIndianapolisLocations = () => async (dispatch) => {
  dispatch({ type: types.INDIANAPOLIS_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchIndianapolisLocations();
    dispatch({
      type: types.FETCH_INDIANAPOLIS_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getJacksonvilleLocations = () => async (dispatch) => {
  dispatch({ type: types.JACKSONVILLE_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchJacksonvilleLocations();
    dispatch({
      type: types.FETCH_JACKSONVILLE_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getRichmondLocations = () => async (dispatch) => {
  dispatch({ type: types.RICHMOND_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchRichmondLocations();
    dispatch({
      type: types.FETCH_RICHMOND_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getStlouisLocations = () => async (dispatch) => {
  dispatch({ type: types.STLOUIS_FETCH_INITIATED, loading: true });
  try {
    const { data } = await api.fetchStlouisLocations();
    dispatch({
      type: types.FETCH_STLOUIS_LOCATION,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const postSignUpNewsLetter = (value) => async (dispatch) => {
  dispatch({ type: types.SEND_SIGN_UP_NEWSLETTER_INITIATED });
  try {
    const { res} = await api.postSingnupnewsletter(value);
    dispatch({
      type: types.SEND_SIGN_UP_NEWSLETTER,
      payload: "Successfully submitted",
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAccordionId = (id, lat, lng) => (dispatch) => {
  dispatch({
    type: types.GET_ACCORDION_ID,
    payload: { lat, lng, id },
  });
};
