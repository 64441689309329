import React, { useState, useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

import Loader from "../assets/shared/Loader";
import GetDirection from "./GetDirection";
import "../assets/styles/mapcomponent.css";

const Marker = ({ children }) => children;

const MapComponent = ({ data }) => {
  const mapRef = useRef();
  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState(null);
  const [activeLocationId, setactiveLocationId] = useState(null);
  const [resLat, setResLat] = useState(null);
  const [resLng, setResLng] = useState(null);
  //props

  const selectedAccordionId = useSelector(
    (state) => state.location.activeAccordId
  );
  const selectedlat = useSelector((state) => state.location.activelat);
  const selectedlng = useSelector((state) => state.location.activelng);

  const locations = data ? data : [];

  const points = locations.map((location) => ({
    type: "feature",
    properties: {
      cluster: false,
      locationId: location.id,
      locationname: location.locationname,
      address: location.address,
      openhours: location.openhours,
      lat: location.lat,
      lng: location.lng,
    },
    geometry: {
      type: "Point",
      coordinates: [location.position.lng, location.position.lat],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: 15,
    },
  });

  const activeMarker = (id) => {
    setactiveLocationId(id);
  };

  //useEffect starts here

  useEffect(() => {
    setactiveLocationId(selectedAccordionId);
    setResLat(selectedlat);
    setResLng(selectedlng);
    if (resLng && resLat && selectedAccordionId) {
      mapRef.current.setZoom(15);
      mapRef.current.panTo({ lat: resLat, lng: resLng });
    }
  }, [selectedlng, selectedlat, selectedAccordionId, resLat, resLng]);

  const apiKey = "AIzaSyDjHFXptPti83q3_V_oI1CHJWgsf7HF4nY";
  // const baseUrl = process.env.REACT_APP_BASE_API_URL;

  // console.log(apiKey, "apiKey")
  // console.log(baseUrl, "baseUrl")

  // ---------****-------------

  return (
    <div className="mapStyle">
      {locations.length > 0 ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            styles: [{ stylers: [{ saturation: -100 }, { gamma: 0 }] }],
          }}
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          defaultCenter={locations[0].position}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
        >
          {clusters.map((cluster) => {
            const [lng, lat] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;
            if (isCluster) {
              return (
                <Marker key={`cluster-${cluster.id}`} lat={lat} lng={lng}>
                  <div
                    className="map-circle"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        15
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: lat, lng: lng });
                      setactiveLocationId(null);
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            return (
              <Marker
                key={`location-${cluster.properties.locationId}`}
                lat={lat}
                lng={lng}
              >
                <div
                  className="map-circle"
                  onClick={() => {
                    mapRef.current.setZoom(15);
                    mapRef.current.panTo({ lat: lat, lng: lng });
                    activeMarker(cluster.properties.locationId);
                  }}
                >
                  <img
                    src="https://bitcoindepot.com/static/ui/assets/images/images/flash.svg"
                    alt="alt"
                  />
                </div>
                {activeLocationId !== null &&
                activeLocationId === cluster.properties.locationId &&
                activeLocationId !== 0 ? (
                  <>
                    <div className="arrow-up"></div>
                    <div className="map-info">
                      <span
                        className="close"
                        onClick={() => setactiveLocationId(null)}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="sm"
                          color="#333"
                        />
                      </span>
                      <div className="map-info-body">
                        <b>{cluster.properties.locationname}</b>
                        <br />
                        {cluster.properties.address}
                      </div>
                      <div className="map-info-body">
                        <b>Open hours:</b>{" "}
                        {cluster.properties.openhours.replaceAll("´┐¢", "-")}
                      </div>
                      <div className="map-info-body">
                        <b>Supported operations:</b>
                        {" Buy Crypto Only"}
                      </div>
                      <GetDirection lat={lat} lng={lng} />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Marker>
            );
          })}
        </GoogleMapReact>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MapComponent;
