import React from "react";
import Template from "./template/Template";
import "./assets/styles/app.css"


const App = () => {
  return (
      <Template></Template>
  );
};

export default App;
