import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/styles/index.css"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore, compose} from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducers from "./reducers";
import "./assets/fonts/Linotype - Trade Gothic Next LT Pro Light.ttf"

const store = createStore(reducers, compose(applyMiddleware(thunk)));


ReactDOM.render(
  <BrowserRouter>
  <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
