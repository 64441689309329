export const FETCH_PORTLAND_LOCATION = "FETCH_PORTLAND_LOCATION";
export const FETCH_TUSCON_LOCATION = "FETCH_TUSCON_LOCATION";
export const FETCH_AUSTIN_LOCATION = "FETCH_AUSTIN_LOCATION";
export const FETCH_BOSTON_LOCATION = "FETCH_BOSTON_LOCATION";
export const FETCH_INDIANAPOLIS_LOCATION = "FETCH_INDIANAPOLIS_LOCATION";
export const FETCH_JACKSONVILLE_LOCATION = "FETCH_JACKSONVILLE_LOCATION";
export const FETCH_RICHMOND_LOCATION = "FETCH_RICHMOND_LOCATION";
export const FETCH_STLOUIS_LOCATION = "FETCH_STLOUIS_LOCATION";
export const GET_ACCORDION_ID = "GET_ACCORDION_ID";
export const PORTLAND_FETCH_INITIATED = "PORTLAND_FETCH_INITIATED";
export const TUSCON_FETCH_INITIATED = "TUSCON_FETCH_INITIATED";
export const AUSTIN_FETCH_INITIATED = "AUSTIN_FETCH_INITIATED";
export const BOSTON_FETCH_INITIATED = "BOSTON_FETCH_INITIATED";
export const INDIANAPOLIS_FETCH_INITIATED = "INDIANAPOLIS_FETCH_INITIATED";
export const JACKSONVILLE_FETCH_INITIATED = "JACKSONVILLE_FETCH_INITIATED";
export const RICHMOND_FETCH_INITIATED = "RICHMOND_FETCH_INITIATED";
export const STLOUIS_FETCH_INITIATED = "STLOUIS_FETCH_INITIATED";
export const SEND_SIGN_UP_NEWSLETTER = "SEND_SIGN_UP_NEWSLETTER";
export const SEND_SIGN_UP_NEWSLETTER_INITIATED = "SEND_SIGN_UP_NEWSLETTER_INITIATED";