import React from "react";
import PropTypes from "prop-types";
import "../assets/styles/header.css";

const Header = ({ locationName, headerText, show }) => {
  return (
    <>
      <div className={`header-top-img ${locationName}`}>
        <div className="header-top-text">
          <h1 className="primary-text">
            Lowest Markup at
            <br /> Bitcoin Depot ATMs in{" "}
            <span className="primary-text-locationname">{headerText}</span>
          </h1>
          <h2 className="secondary-text">
            BUY BITCOIN, ETHEREUM AND LITECOIN AT <br /> BITCOIN DEPOT ATMs
            WITHIN MINUTES
          </h2>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  locationName: PropTypes.string.isRequired,
};

export default Header;
