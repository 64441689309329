import axios from "axios";

const url = `${process.env.REACT_APP_BASE_API_URL}`;

const PORTLAND_URL = `${url}/locations/portland/`;
const TUCSON_URL = `${url}/locations/tucson/`;
const AUSTIN_URL = `${url}/locations/austin/`;
const BOSTON_URL = `${url}/locations/boston/`;
const INDIANAPOLIS_URL = `${url}/locations/indianapolis/`;
const JACKSONVILLE_URL = `${url}/locations/jacksonville/`;
const RICHMOND_URL = `${url}/locations/richmond/`;
const STLOUIS_URL = `${url}/locations/stLouis/`;
const SIGN_UP = `${url}/locations/newsletter/`;

export const fetchPortlandLocations = () => axios.get(PORTLAND_URL);
export const fetchTusconLocations = () => axios.get(TUCSON_URL);
export const fetchAustinLocations = () => axios.get(AUSTIN_URL);
export const fetchBostonLocations = () => axios.get(BOSTON_URL);
export const fetchIndianapolisLocations = () => axios.get(INDIANAPOLIS_URL);
export const fetchJacksonvilleLocations = () => axios.get(JACKSONVILLE_URL);
export const fetchRichmondLocations = () => axios.get(RICHMOND_URL);
export const fetchStlouisLocations = () => axios.get(STLOUIS_URL);
export const postSingnupnewsletter = (value) => axios.post(SIGN_UP, value);
