import React from "react";
import "../assets/styles/footer.css";
import ButtonMailto from "./ButtonMailTo";
//import { Link } from "react-router-dom";

// const footerListItem = [
//   { link: "USA (678)435- 9604", name: "USA (678)435- 9604" },
//   { link: "support@bitcoindepot.com", name: "support@bitcoindepot.com" },
//   { link: "", name: "2870 Peachtree Rd #327" },
//   { link: "", name: "Atlanta, Georgia, 30305" },
// ];
const footerListInfo = [
  { name: "Location", link: "https://bitcoindepot.com/locations/" },
  { name: "Host a Location", link: "https://bitcoindepot.com/locations/" },
  { name: "Faq", link: "https://bitcoindepot.zendesk.com/hc/en-us" },
  { name: "Legal & privacy", link: "https://bitcoindepot.com/privacy/" },
  { name: "Terms & Conditions", link: "https://bitcoindepot.com/terms/" },
  {
    name: "Policies",
    link: "https://bitcoindepot.com/refund/",
  },
];
const footerListSocial = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2FBitcoinDepot%2F",
    color: "#0070dd",
  },
  {
    name: "Twitter",
    link: "https://twitter.com/bitcoin_depot",
    color: "#41c8ff",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/accounts/login/",
    color: "#ec69ff",
  },
];
const footerListGeneral = [
  { name: "About", link: "https://bitcoindepot.com/team/" },
  { name: "Careers", link: "https://bitcoindepot.com/careers/" },
  { name: "Press", link: "https://bitcoindepot.com/blog/" },
  { name: "Blog", link: "https://bitcoindepot.com/blog/" },
  { name: "Guides", link: "https://bitcoindepot.com/guides/" },
];
const Footer = () => {
  return (
    <div>
      <div className="copyright-bottom-text">
        <a href="https://bitcoindepot.com/privacy/#rights" rel="noreferrer">
          Do Not Sell My Personal Information
        </a>
      </div>
      <footer id="footer">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-1"></div>
              <div className="col-xs-12 col-sm-6 col-md-4 footer-center">
                <div>
                  <div className="footer-title">SAY HI</div>
                  <ul className="footer-list">
                    <li className="footer-list-item">
                      USA
                      <a
                        href="tel:678-435-9604"
                        style={{
                          color: "rgba(30, 30, 30, 0.8)",
                          textDecoration: "none",
                        }}
                      >
                        (678)435- 9604
                      </a>
                    </li>
                    <li className="footer-list-item">
                      <ButtonMailto
                        label="support@bitcoindepot.com"
                        mailto="mailto:support@bitcoindepot.com"
                      />
                    </li>
                    <li className="footer-list-item">2870 Peachtree Rd #327</li>
                    <li className="footer-list-item">
                      Atlanta, Georgia, 30305
                    </li>
                  </ul>
                  <ul className="footer-list-social">
                    {footerListSocial.map((socialLink, i) => (
                      <li key={i} className="footer-list-social-item">
                        <a
                          href={socialLink.link}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: socialLink.color }}
                        >
                          {socialLink.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 footer-center">
                <div>
                  <div className="footer-title">SITEMAP</div>
                  <ul className="footer-list">
                    {footerListInfo.map((listItem, i) => (
                      <li className="footer-list-item-info" key={i}>
                        <a href={listItem.link} rel="noreferrer">
                          {listItem.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 footer-center">
                <div>
                  <div className="footer-title">GENERAL</div>
                  <ul className="footer-list">
                    {footerListGeneral.map((listItem, i) => (
                      <li className="footer-list-item-info" key={i}>
                        <a href={listItem.link} rel="noreferrer">
                          {listItem.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-1"></div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright">
                  <p className="copyright-text text-center">
                    BITCOIN DEPOT © 2021
                  </p>
                  <p className="text-center">
                    Lux Vending, LLC d/b/a Bitcoin Depot
                  </p>
                  <p className="text-center">NMLS # 1886902</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
