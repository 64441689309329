import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getAccordionId } from "../actions/locationAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

import "../assets/styles/accordion.css";
import GetDirection from "./GetDirection";

const Accordion = (props) => {
  //state
  const [selected, setSelected] = useState(null);
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(8);

  const dispatch = useDispatch();

  const { datas } = props;

  useEffect(() => {
    setItems(datas);
  }, [datas]);
  //props

  //function
  const loadMore = () => {
    for (let i = 0; i < items.length; i++) {
      if (i === items.length - 1) {
        setVisible(visible + 8);
      }
    }
  };

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <div className="acoordion">
        {items.length > 0 &&
          items.slice(0, visible).map((data, i) => (
            <div className="item" key={i}>
              <div className="title" onClick={() => toggle(i)}>
                <div className="title-primary">
                  BITCOIN ATM AT {data.locationname}
                </div>
                <span>
                  {selected === i ? (
                    <FontAwesomeIcon icon={faTimes} size="1x" color="#b78fe7" />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} size="1x" />
                  )}
                </span>
              </div>
              <div
                className={selected === i ? "content show" : "content"}
                onClick={() =>
                  dispatch(
                    getAccordionId(
                      data.id,
                      data.position.lat,
                      data.position.lng
                    )
                  )
                }
              >
                <div className="content-address">{data.address}</div>
                <div className="content-getdirection">
                  <GetDirection
                    lat={data.position.lat}
                    lng={data.position.lng}
                  />
                </div>
              </div>
            </div>
          ))}
        <div>
          {visible > items.length ? (
            ""
          ) : (
            <button type="button" className="btn loadmore" onClick={loadMore}>
              Load More
            </button>
          )}
        </div>
      </div>
      {items && items.length <= 0 ? (
        <div style={{ width: "100%" }}>
          <div className="alertLocationError">No location found... !</div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

Accordion.propTypes = {
  datas: PropTypes.array,
};

export default Accordion;
