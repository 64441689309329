import React from "react";
import "../assets/styles/getdirection.css"

const GetDirection = (props) => {
  const { lat, lng } = props;
  return (
    <div>
      <a
        href={`https://maps.google.com?saddr=Current+Location&daddr=${lat},${lng}`}
        target="_blank"
        rel="noreferrer"
        className="g-t-d"
      >
        Get Directions
      </a>
    </div>
  );
};

export default GetDirection;
