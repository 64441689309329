import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/styles/signup.css";
import ButtonMailto from "./ButtonMailTo";
import { postSignUpNewsLetter } from "../actions/locationAction";

const SignupComponent = ({ type, placeholder, value, status }) => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState("");

  const signupformhandler = (e) => {
    e.preventDefault();
    dispatch(postSignUpNewsLetter({ email: formValue }));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFormValue(value);
  };

  return (
    <div>
      <div className="signupText">
        SIGN UP FOR EXCLUSIVE UPDATES
        <br /> AND DEALS IN YOUR AREA
      </div>
      <div className="sinup-form">
        <form className="d-Form" onSubmit={signupformhandler}>
          <input
            className="form-control s-control"
            type={type}
            placeholder={placeholder}
            aria-label="Search"
            value={value}
            onChange={handleChange}
          />

          <button className="btn d-button">SIGN UP</button>
        </form>
      </div>
      <div>
        <p style={{ color: "grey", fontSize: "12px" }}>
          {status ? status : ""}
        </p>
      </div>
      <div className="signupInfoSection">
        <div className="helpText">NEED HELP?</div>
        <div style={{ marginTop: "20px" }}>
          Bitcoin Depot is here to help!
          <br />
          Call or email for support.
        </div>
        <div
          style={{
            fontWeight: "bold",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <a
            href="tel:678-435-9604"
            style={{
              color: "#212529",
              textDecoration: "none",
            }}
          >
            (678)435- 9604
          </a>
          <br />
          <div>
            <ButtonMailto
              label="support@bitcoindepot.com"
              mailto="mailto:support@bitcoindepot.com"
              black
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
