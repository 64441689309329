import React, { useState } from "react";
//import PropTypes from "prop-types";
import { Routes, Route } from "react-router-dom";

import "../assets/styles/navigation.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

//app imports

import Portland from "../screens/Portland";
import Austin from "../screens/Austin";
import Boston from "../screens/Boston";
import Indianapolis from "../screens/Indianapolis";
import Jacksonville from "../screens/Jacksonville";
import Richmond from "../screens/Richmond";
import Stlouis from "../screens/Stlouis";
import Tuscon from "../screens/Tuscon";

const Navigation = () => {
  const [isOpen, SetisOpen] = useState(false);
  return (
    <>
      <Navbar light expand="md" sticky="top" style={{ background: "#fff" }}>
        <NavbarBrand href="https://bitcoindepot.com/">
          <img
            src="https://bitcoindepot.com/static/ui/assets/images/images/logo.webp"
            alt="logo"
            height="46px"
            width="100%"
          />
        </NavbarBrand>
        <NavbarToggler onClick={() => SetisOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar style={{ fontSize: "13px" }}>
            <NavItem>
              <NavLink to="/">
                <a
                  href="https://bitcoindepot.com/locations/"
                  rel="noreferrer"
                >
                  LOCATIONS
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/">
                <a
                  href="https://bitcoindepot.com/otc/"
                  rel="noreferrer"
                >
                  OTC
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  href="https://bitcoindepot.com/buy-online/"
                  rel="noreferrer"
                >
                  BUY ONLINE
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  href="https://bitcoindepot.com/host/"
                  rel="noreferrer"
                >
                  PARTNER WITH US
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  href="https://bitcoindepot.com/team/"
                  rel="noreferrer"
                >
                  COMPANY
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  href="https://bitcoindepot.zendesk.com/hc/en-us"
                  rel="noreferrer"
                >
                  FAQ
                </a>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <a
                  href="https://rewards.bitcoindepot.com/"
                  rel="noreferrer"
                >
                  REDEEM
                </a>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Routes>
        <Route path="/portland" element={<Portland />} />
        <Route path="/austin" element={<Austin />} />
        <Route path="/boston" element={<Boston />} />
        <Route path="/indianapolis" element={<Indianapolis />} />
        <Route path="/jacksonville" element={<Jacksonville />} />
        <Route path="/richmond" element={<Richmond />} />
        <Route path="/stlouis" element={<Stlouis />} />
        <Route path="/tucson" element={<Tuscon />} />
      </Routes>
    </>
  );
};

//Navigation.propTypes = {};

export default Navigation;
