import React from "react";
import { useDispatch } from "react-redux"
import "../assets/styles/form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Form = ({
  value,
  label,
  placeholder,
  onChange,
  text,
  onSubmit,
  type,
  actionName,
  payload,
}) => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { value } = e.target;
    onChange(value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (actionName) {
      dispatch(actionName(payload));
    }
  };
  return (
    <div>
      <form className="d-Form" onSubmit={submitHandler}>
        <input
          className={text ? "form-control wd" : "form-control"}
          type={type}
          placeholder={placeholder}
          aria-label="Search"
          value={value}
          onChange={handleChange}
        />

        <button className="btn d-button">
          {text ? (
            "SIGN UP"
          ) : (
            <FontAwesomeIcon icon={faSearch} size="1x" color="#000" />
          )}
        </button>
      </form>
    </div>
  );
};

export default Form;
