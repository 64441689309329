import React from 'react';
import "../styles/loader.css"

const Loader = () => {
    return (
      <div className="loader">
        <div className="outer"></div>
        <div className="middle"></div>
        <div className="inner"></div>
      </div>
    );
}

export default Loader
